var services = [
    {
        "ServiceIdentifier": "PDROPDETECTIONS"
    },
    {
        "ServiceIdentifier": "PDROPDETECTIONMS"
    },
    {
        "ServiceIdentifier": "PDROPDETECTIONF"
    },
    {
        "ServiceIdentifier": "PDROPDETECTIONMF"
    },
    {
        "ServiceIdentifier": "FDROPDETECTIONS"
    },
    {
        "ServiceIdentifier": "FDROPDETECTIONMS"
    },
    {
        "ServiceIdentifier": "FDROPDETECTIONF"
    },
    {
        "ServiceIdentifier": "FDROPDETECTIONMF"
    },
    {
        "ServiceIdentifier": "DATABUFFERPD"
    },
    {
        "ServiceIdentifier": "DATABUFFERPP"
    },
    {
        "ServiceIdentifier": "OPC"
    },
    {
        "ServiceIdentifier": "RASTER"
    },
    {
        "ServiceIdentifier": "INFLUXDBWRITER"
    },
    {
        "ServiceIdentifier": "INFLUXDBRESULTSWRITER"
    },
    {
        "ServiceIdentifier": "INFLUXDBREADERRT"
    },   
    {
        "ServiceIdentifier": "INFLUXDBREADERPEA"
    },
    {
        "ServiceIdentifier": "AVERAGE"
    },
    {
        "ServiceIdentifier": "PPINPOINTS"
    },
    {
        "ServiceIdentifier": "PPINPOINTMS"
    },
    {
        "ServiceIdentifier": "PPINPOINTF"
    },
    {
        "ServiceIdentifier": "PPINPOINTMF"
    },
    {
        "ServiceIdentifier": "FPINPOINTS"
    },
    {
        "ServiceIdentifier": "FPINPOINTMS"
    },
    {
        "ServiceIdentifier": "FPINPOINTF"
    },
    {
        "ServiceIdentifier": "FPINPOINTMF"
    },
    {
        "ServiceIdentifier": "FILTER"
    },
    {
        "ServiceIdentifier": "CHANGEVOLUME"
    },
    {
        "ServiceIdentifier": "LEVELIZATION"
    },
    {
        "ServiceIdentifier": "VDROPDETECTIONS"
    },
    {
        "ServiceIdentifier": "VDROPDETECTIONF"
    },
    {
        "ServiceIdentifier": "SEPARATOR"
    },
    {
        "ServiceIdentifier": "PGL"
    },
    {
        "ServiceIdentifier": "QUANTITYCHANGE"
    },
    
    {
        "ServiceIdentifier": "QDROPDETECTIONF"
    },
    {
        "ServiceIdentifier": "QDROPDETECTIONS"
    },
    {
        "ServiceIdentifier": "QUANTITYCHANGEZEROSHIFT"
    },
    {
        "ServiceIdentifier": "QDROPDETECTIONZEROSHIFTS"
    },
    {
        "ServiceIdentifier": "QDROPDETECTIONZEROSHIFTF"
    },
    {
        "ServiceIdentifier": "QILEAKDETECTOR"
    },
    {
        "ServiceIdentifier": "QIMONITORING"
    },
    {
        "ServiceIdentifier": "QUANTITYCHANGEVALIDATOR"
    },
    {
        "ServiceIdentifier": "QILEAKVALIDATOR"
    },
    // {
    //     "ServiceIdentifier": "QILEAKDETECTORZEROSHIFT"
    // },
    // {
    //     "ServiceIdentifier": "QIMONITORINGZEROSHIFT"
    // },
    {
        "ServiceIdentifier": "SMDP"
    },
    {
        "ServiceIdentifier": "SM"
    },
    {
        "ServiceIdentifier": "ANOMALYDETECTION"
    }
]

export default services;